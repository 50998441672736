import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useWindowSize } from "../../../utils/useWindowSize.js";
import { submitContactUs } from '../../../api/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contactus.scss';

let mobileBreakpoint = 821;
const ContactUsForm = () => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)

    const schema = Yup.object().shape({
        message: Yup.string().required('Message is required'),
        feedbackTitle: Yup.string().required('Title is required'),
        feedbackEmail: Yup.string().email('Invalid email').required('Email is required'),

    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width])

    return (
        <div className='contact-us-wrapper'>
            <Formik
                validationSchema={schema}
                initialValues={{ feedbackTitle: '', message: '', feedbackEmail: '' }}
                onSubmit={async (values, { resetForm }) => {
                    let body = {
                        title: values.feedbackTitle,
                        email: values.feedbackEmail,
                        message: values.message
                    }
                    try {
                        let resp = await submitContactUs(body);
                        if (resp) {
                            toast.success(resp.message);
                            resetForm()
                        }
                    }
                    catch (err) {
                        toast.error(err?.response?.data?.detail);
                    }

                }}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                    /* and other goodies */
                }) => (
                    <form
                        className="login-form mt-3"
                        onSubmit={handleSubmit}
                        noValidate>
                        <div className="Auth-form-content">

                            <Form.Group controlId="feedbackTitle">
                                <div
                                    className={`form-floating mb-3 ${touched.feedbackTitle && errors.feedbackTitle
                                        ? 'has-validation-error'
                                        : ''
                                        }`}>
                                    <Form.Control
                                        className={"w-100 mt-2 p-2 feedback-input"}
                                        type='text'
                                        name="feedbackTitle"
                                        placeholder="Title"
                                        value={values.feedbackTitle}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.feedbackTitle && errors.feedbackTitle}
                                    />
                                    <Form.Control.Feedback type="invalid" className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"}>
                                        {errors.feedbackTitle}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="feedbackEmail">
                                <div
                                    className={`form-floating mb-3 ${touched.feedbackEmail && errors.feedbackEmail
                                        ? 'has-validation-error'
                                        : ''
                                        }`}>
                                    <Form.Control
                                        className={"w-100 mt-2 p-2 feedback-input"}
                                        type='email'
                                        name="feedbackEmail"
                                        placeholder="Your Email"
                                        value={values.feedbackEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.feedbackEmail && errors.feedbackEmail}
                                    />
                                    <Form.Control.Feedback type="invalid" className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"}>
                                        {errors.feedbackEmail}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="message">
                                <div
                                    className={`form-floating mb-3 ${touched.message && errors.message
                                        ? 'has-validation-error'
                                        : ''
                                        }`}>
                                    <Form.Control
                                        className={"w-100 mt-2 p-2 feedback-input"}
                                        as="textarea" rows={6}
                                        style={{ height: '115px' }}
                                        name="message"
                                        placeholder="Your Message"
                                        value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.message && errors.message}
                                    />
                                    <Form.Control.Feedback type="invalid" className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"}>
                                        {errors.message}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <div className="d-grid gap-2 mt-3">
                                <button
                                    type="submit"
                                    className={getwidth >= mobileBreakpoint ? "btn btn-primary reset-btn" : "btn btn-primary reset-btn w-100"}>
                                    Send
                                </button>
                            </div>

                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}
export default ContactUsForm;