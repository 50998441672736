import React, { useEffect, useState } from "react"
import svg from "../../../assets/images/svg/index"
import { Formik } from "formik"
import * as Yup from "yup"
import { Form } from "react-bootstrap"
import { login } from "../../../api/request"
import { useNavigate, useLocation } from "react-router-dom"
import { useWindowSize } from "../../../utils/useWindowSize.js"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./styles.scss"
import ShowAgreement from "./showAgreement.jsx"

let mobileBreakpoint = 821
const Login = () => {
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const [showAgreement, setShowAgreement] = useState(false);
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .test("has-supported-tld", "Invalid email", value => {
        if (!value) {
          return false // Handle empty values as needed
        }

        const supportedTlds = [
          ".com",
          ".us",
          ".in",
          ".net",
          ".org",
          ".gov",
          ".edu",
          ".uk",
          ".au",
          ".co",
          ".io"
        ]
        const regex = new RegExp(`(${supportedTlds.join("|")})$`, "i")

        return regex.test(value)
      })

    // password: Yup.string().required('Password is required').matches(
    //     /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
    //     "Password must contain at least 8 characters, one capital letter, one number, and one special character"),
  })

  return (
    <div className="login-wrapper">
      {getwidth >= mobileBreakpoint && (
        <div className="signup-img-wrapper">
          <img src={svg?.LoginBg} className="signup-bg" alt="signup-Np" />
          <div
            className="landing-page-txt"
            onClick={() => (window.location.href = "/home")}
          >
            Go to Landing Page
          </div>
        </div>
      )}
      <div className="signup-form">
        <div className="signup-form-body w-100">
          <img src={svg?.LoginLogo} className="app-logo" alt="app logo NP" />
          <Formik
            validationSchema={schema}
            initialValues={{ email: "", password: "", license_agreement: false }}
            onSubmit={async values => {
              let body = {
                username: values.email,
                password: values.password
              }
              setShowPassword(false)
              if (values.password === "") {
                toast.error("Password is mandatory")
                return
              }
              if(!agreementAccepted){
                toast.error("Please agree to the Confidentiality Agreement")
                return
              }
              try {
                let resp = await login(body)
                if (resp?.user?.must_change_password === true) {
                  localStorage?.setItem("isLoggedin", true)
                  localStorage?.setItem(
                    "businessName",
                    resp?.user?.business_name
                  )
                  localStorage?.setItem("userEmail", resp?.user?.email)
                  localStorage?.setItem("token", resp?.token)
                  navigate("/update-password", {
                    state: { must_change_password: true }
                  })
                } else {
                  navigate("/home")
                  localStorage?.setItem("isLoggedin", true)
                  localStorage?.setItem(
                    "businessName",
                    resp?.user?.business_name
                  )
                  localStorage?.setItem("userEmail", resp?.user?.email)
                  localStorage?.setItem("token", resp?.token)
                  localStorage?.setItem(
                    "orgType",
                    resp?.user?.business_category
                  )
                }
              } catch (error) {
                toast.error(
                  error?.response?.data?.message ||
                    "An error occurred while signing in."
                )
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
              /* and other goodies */
            }) => (
              <form className="login-form" onSubmit={handleSubmit} noValidate>
                <div className="Auth-form-content w-100">
                  <div className="signin_heading">Sign In</div>
                  <div className="signin_subheading mt-1">
                    Please enter your details to signin{" "}
                  </div>

                  <Form.Group controlId="email">
                    <div
                      className={`form-floating mb-3 mt-2 ${
                        touched.email && errors.email
                          ? "has-validation-error"
                          : ""
                      }`}
                    >
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Business Email *"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-100"
                        isInvalid={touched.email && errors.email}
                      />
                      {!values.email && (
                        <label htmlFor="email" className="greyLabel">
                          Business Email{" "}
                          <span className="required-icon">*</span>
                        </label>
                      )}
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="password">
                    <div
                      className={`form-floating mb-3 ${
                        touched.password && errors.password
                          ? "has-validation-error"
                          : ""
                      }`}
                    >
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // isInvalid={touched.password && errors.password}
                      />
                      {!values.password && (
                        <label htmlFor="email" className="greyLabel">
                          Password<span className="required-icon">*</span>
                        </label>
                      )}
                      <div className="input-group-append eyeicon-img">
                        <img
                          src={
                            showPassword ? svg?.PasswordShow : svg?.PasswordHide
                          }
                          style={{ color: "#2563EB" }}
                          onClick={() => {
                            setShowPassword(!showPassword)
                          }}
                          alt=""
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="license_agreement">
                    <div className="license-agreement">
                      <Form.Check
                        type="checkbox"
                        label="Confidentiality Agreement"
                        onClick={(e) => { 
                          if(!agreementAccepted){
                            setShowAgreement(true)
                          }
                          else{
                            setAgreementAccepted(!agreementAccepted)
                          }
                          }}
                        checked={agreementAccepted}
                      />
                    </div>
                  </Form.Group>
                  <div
                    className="forgot-pass"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot password?
                  </div>

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary signin_btn"
                      style={{ width: "100%" }}
                    >
                      Sign In
                    </button>
                  </div>

                  <div
                    className="no-account-txt mt-2 cursor-pointer"
                    onClick={() => navigate("/sign-up")}
                  >
                    Do not have an account{" "}
                    <span className="login_here"> Sign up</span>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {showAgreement && (
      <ShowAgreement 
        show={showAgreement} 
        setShow={setShowAgreement} 
        setAgreementAccepted = {setAgreementAccepted} 
      />)}
    </div>
  )
}

export default Login
