import axios from "axios"
import { APP_JSON_HEADER, BASE_URL, successStatusCode } from "./config"

import EndPoints from "./endPoints"

const GET = "GET"
const POST = "POST"
const PUT = "PUT"
const DELETE = "DELETE"
const PATCH = "PATCH"

export async function request(
  endPoint,
  method,
  body,
  passToken = true,
  formDataParam = "false"
) {
  let APIURL = BASE_URL + endPoint
  const header = await APP_JSON_HEADER(passToken, formDataParam)

  return await axios({
    method: method,
    url: APIURL,
    data: body,
    headers: header
  })
    .then(function (response) {
      if (successStatusCode.includes(response?.status)) return response?.data
    })
    .catch(error => {
      console.log(error)
      throw error
    })
}
export async function termsAndCondtions(params) {
  return request(EndPoints.termsConditions, GET, params, false)
}
export async function privacyPolicy(params) {
  return request(EndPoints.privacyPolicyUrl, GET, params, false)
}
export async function submitContactUs(params) {
  return request(EndPoints.contactUs, POST, params, false)
}
export async function login(params) {
  return request(EndPoints.userLogin, POST, params, false)
}
export async function userSignup(params) {
  return request(EndPoints.signup, POST, params, false)
}
export async function fetchBusinessProfile(params) {
  return request(EndPoints.businessProfile, GET, params, false)
}
export async function userLogout(params) {
  return request(EndPoints.logout, POST, params)
}
export async function forgotPass(params) {
  return request(EndPoints.forgotPassword, POST, params)
}
export async function resetPassword(params) {
  return request(EndPoints.resetPass, POST, params)
}
export async function changePassword(params) {
  return request(EndPoints.changePass, POST, params)
}
export async function updatePassword(params) {
  return request(EndPoints.updatePass, POST, params)
}
export async function getUserProfile(params) {
  return request(EndPoints.userProfile, GET, params)
}
export async function updateUserProfile(params, id) {
  return request(`${EndPoints.userProfile}${id}/`, PATCH, params)
}
export async function getSurvey(params) {
  return request(EndPoints.survey, GET, params)
}
export async function saveSurvey(params, id) {
  return request(`${EndPoints.survey}${id}/save-survey/`, POST, params)
}
export async function getSurveyListing(params) {
  return request(`${EndPoints.surveyListing}?page=${params}`, GET, params)
}
export async function getSurveyDateListing(params, page) {
  return request(
    `${EndPoints.surveyListing}?search=${params}&page=${page}`,
    GET,
    params
  )
}
export async function getSurveyDetail(params) {
  return request(`${EndPoints.surveyListing}${params}/`, GET, params)
}
export async function sendFeedback(params) {
  return request(`${EndPoints.feedback}`, POST, params, true, "true")
}

export async function getSubscriptions() {
  return request(`${EndPoints.subscriptions}`, GET, "", true)
}

export async function verifyAccessCode(params) {
  return request(`${EndPoints.verifyAccessCode}`, POST, params, true)
}

export async function confirmPayment(params) {
  return request(`${EndPoints.confirmPayment}`, POST, params, true)
}

export async function getPaymentMethod() {
  return request(`${EndPoints.PaymentMethod}`, GET, "", true)
}

export async function removePaymentMethod(params) {
  return request(`${EndPoints.PaymentMethod}`, DELETE, params, true)
}
