import React from "react"
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js"
import { PolarArea, Radar } from "react-chartjs-2"
import "./spidergraph.scss"

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement
)

const SpiderGraph = props => {
  let sections = props?.spiderData?.sections
  let sectionsArr = []
  let sectionsData = []
  for (let i = 0; i < sections?.length; i++) {
    let currSectionArr = []
    currSectionArr.push(sections[i]?.section_name)
    currSectionArr.push(Math.floor(sections[i]?.percentage) + "%")
    sectionsArr.push(currSectionArr)
    // sectionsArr?.push(sections[i]?.section_name + " (" + `${Math.floor(sections[i]?.percentage)
    // }%` + ")");
    sectionsData?.push(
      (sections[i]?.correct_question / sections[i]?.total_question) * 5
    )
  }
  console.log("sectionsData", sectionsData)
  const data = {
    labels: sectionsArr,
    datasets: [
      {
        label: "Sections",
        data: sectionsData,
        backgroundColor: [
            '#FF6384',
            '#4BC0C0',
            '#FFCD56',
            '#C9CBCF',
            '#36A2EB',
            '#003F5C',
            '#58508D',
            '#BC5090',
          ],
      },
    ]
  }

  const options = {
    scale: {
      ticks: {
        min: 0, // Set the maximum value to 0
        max: 5, // Set the maximum value to 5
        stepSize: 1,
        color: "#0B2E64",
        backgroundColor: "transparent",
        font: {
          family: "Times New Roman",
          size: 0
        },
        beginAtZero: false,
        // maxTicksLimit: 100,
        callback: function (value, index, values) {
          return value.toFixed(2)
        }
      }
    },
    scales: {
      r: {
        min: 0,
        max: 5,
        angleLines: {
          display: true,
        },
        pointLabels: {
          display: true,
          centerPointLabels: true,
          color: "black",
          font: {
            family: "Times New Roman",
            size: 14,
            weight: 700
          }
        },
        grid: {
          color: "#7DC5DD80",
          drawTicks: false,
          lineWidth: 3
        }
      }
    },
    plugins: {
        legend: {
            display: false,
            // position: 'bottom',
          },
    },
    maintainAspectRatio: true,
    responsive: true
  }

  return (
    <div className="spider-graph-container">
      <PolarArea data={data} options={options} />
    </div>
  )
}

export default SpiderGraph
