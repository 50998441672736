const numToWordObj = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
  13: "thirteen",
  14: "fourteen",
  15: "fifteen",
  16: "sixteen",
  17: "seventeen",
  18: "eighteen",
  19: "nineteen",
  20: "twenty",
  30: "thirty",
  40: "forty",
  50: "fifty",
  60: "sixty",
  70: "seventy",
  80: "eighty",
  90: "ninety"
}
const placement = {
  100: " hundred",
  1000: " thousand",
  1000000: " million",
  1000000000000: " trillion"
}

export const numToWord = num => {
  const limiter = val => num < val
  const limiterIndex = Object.keys(placement).findIndex(limiter)
  const limiterKey = Object.keys(placement)[limiterIndex]
  const limiterVal = Object.values(placement)[limiterIndex - 1]
  const limiterMod = Object.keys(placement)[limiterIndex - 1]

  if (numToWordObj[num]) {
    return numToWordObj[num]
  }
  if (num < 100) {
    let whole = Math.floor(num / 10) * 10
    let part = num % 10
    return numToWordObj[whole] + " " + numToWordObj[part]
  }

  if (num < limiterKey) {
    let whole = Math.floor(num / limiterMod)
    let part = num % limiterMod
    if (part === 0) {
      return numToWord(whole) + limiterVal
    } else {
      return numToWord(whole) + limiterVal + " and " + numToWord(part)
    }
  }
}
