import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Navbar from "../../../CustomComponents/Navbar"
import BottomNavbar from "../../../CustomComponents/BottomNavbar"
import SurveyForm from "../Dashboard/SurveyForm.jsx"
import SurveyListing from "../Dashboard/SurveyListing.jsx"
import { fetchBusinessProfile, getUserProfile } from "../../../api/request"
import { useWindowSize } from "../../../utils/useWindowSize.js"
import SendFeedback from "../Dashboard/SendFeedback.jsx"
import svg from "../../../assets/images/svg/index"
import Select from "react-select"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../Dashboard/styles.scss"

let mobileBreakpoint = 820
const History = () => {
  const [orgTypeVal, setOrgTypeVal] = useState(null)
  const [orgTypeOptions, setOrgTypeOptions] = useState([])
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const [useData, setUserData] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchBusinessData()
  }, [])



  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  useEffect(() => {
    fetchUserProfileInfo()
  }, [])

  const fetchUserProfileInfo = async () => {
    try {
      let resp = await getUserProfile()
      setUserData(resp[0])
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while fetching profile info"
      )
    }
  }

  const fetchBusinessData = async uData => {
    try {
      let resp = await fetchBusinessProfile()
      let arr = []
      for (let i = 0; i < resp?.length; i++) {
        arr?.push({
          label: resp[i]?.name,
          value: resp[i]?.id
        })
      }
      setOrgTypeOptions(arr)
      let temp = arr?.filter(
        item => item?.value === Number(localStorage.getItem("orgType"))
      )
      setOrgTypeVal(temp[0])
    } catch (err) {
      toast.error(err?.response?.data?.message)
    }
  }

  const handleOrgTypeDropdown = event => {
    setOrgTypeVal(event)
  }
  return (
    <>
      <Navbar />
      <div className="page-content">
        <div className="dashboard-wrapper" style={{paddingTop:15}}>
          
          <div className="survey-listing-wrapper"  id="survey">
            <SurveyListing />
          </div>
        </div>

        <div className="feedback-wrapper">
          <SendFeedback />
        </div>
      </div>
      <BottomNavbar />
    </>
  )
}

export default History
