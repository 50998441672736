import React, { useEffect, useState } from "react";
import svg from '../../../assets/images/svg/index';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import { userSignup, fetchBusinessProfile } from '../../../api/request';
import SignupConfirmation from './SignupConfirmation.jsx';
import { useWindowSize } from "../../../utils/useWindowSize.js";
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

let mobileBreakpoint = 821;
const Signup = () => {
    const { control, handleSubmit, formState: { errors } } = useForm();
    const phoneRegExp = /^\+?([0-9]{1,4})?[ .-]?\(?([0-9]{3})\)?[ .-]?([0-9]{3})[ .-]?([0-9]{4})$/;
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [orgTypeOptions, setOrgTypeOptions] = useState([]);
    const [orgTypeVal, setOrgTypeVal] = useState(null);
    const [orgTypeError, setorgTypeError] = useState(false);
    const [signupConfirm, setsignupConfirm] = useState(false);
    const [showPhoneError, setPhoneError] = useState(false);
    const [phoneErrorMsg, setphoneErrorMsg] = useState('');
    const [businessData, setBusinessData] = useState([]);
    const [phoneVal, setPhoneVal] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchBusinessData();
    }, []);

    const fetchBusinessData = async () => {
        try {
            let resp = await fetchBusinessProfile();
            setBusinessData(resp);
            let arr = [];
            for (let i = 0; i < resp?.length; i++) {
                arr?.push({
                    label: resp[i]?.name,
                    value: resp[i]?.id
                })
            }
            setOrgTypeOptions(arr);
            // setOrgTypeVal(arr[0]);
        }
        catch (err) {
            toast.error(err?.response?.data?.message);
        }
    }

    const handlePhoneChange = (e) => {
        setPhoneError(false);
        setPhoneVal(e);
    }

    const schema = Yup.object().shape({
        email: Yup.string()
        .email('Invalid email')
        .required('Email is required')
        .test(
            'has-supported-tld',
            'Invalid email',
            (value) => {
                if (!value) {
                    return false; // Handle empty values as needed
                }

                const supportedTlds = ['.com', '.us', '.in', '.net', '.org', '.gov', '.edu', '.uk', '.au', '.co', '.io'];
                const regex = new RegExp(`(${supportedTlds.join('|')})$`, 'i');

                return regex.test(value);
            }
        ),
        businessName: Yup.string().required('Business name is required'),
        contactName: Yup.string().required('Contact person name is required'),
    });

    const handleOrgTypeDropdown = (event) => {
        setOrgTypeVal(event);
    }

    return (
        <div className="signup-wrapper">
            {
                getwidth >= mobileBreakpoint &&
                <div className="signup-img-wrapper">
                    <img src={svg?.LoginBg} className="signup-bg" alt="signup-Np" />
                    <div className="landing-page-txt" onClick={() => window.location.href = '/home'}>Go to Landing Page</div>
                </div>

            }
            <div className="signup-form">
                <div className="signup-form-body">
                    <img src={svg?.LoginLogo} className="app-logo" alt="app logo NP" />
                    {
                        signupConfirm === true ?
                            <SignupConfirmation />
                            :
                            <Formik
                                validationSchema={schema}
                                initialValues={{ businessName: '', contactName: '', email: '', Address: '' }}
                                onSubmit={async (values) => {
                                    if (orgTypeVal === null) {
                                        setorgTypeError(true);
                                        return;
                                    }
                                    else {
                                        setorgTypeError(false);
                                    }
                                    if (!phoneRegExp.test(phoneVal)) {
                                        setPhoneError(true);
                                        setphoneErrorMsg('Invalid phone number. Please use the format XXX-XXX-XXXX')
                                        return;
                                    }
                                    let body = {
                                        "business_name": values?.businessName,
                                        "business_category": orgTypeVal?.value,
                                        "name": values?.contactName,
                                        "email": values?.email,
                                        "address": values?.Address,
                                        "phone": phoneVal
                                    }
                                    try {
                                        let resp = await userSignup(body);
                                        if (resp) {
                                            setsignupConfirm(true);
                                            // localStorage?.setItem('userEmail', values?.email);
                                            // navigate('/verify-email');
                                        }
                                    }
                                    catch (error) {
                                        toast.error(error?.response?.data?.message || 'An error occurred while creating an account.')
                                    }

                                }}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue
                                    /* and other goodies */
                                }) => (
                                    <form
                                        className="login-form"
                                        onSubmit={handleSubmit}
                                        noValidate>
                                        <div className="Auth-form-content w-100">
                                            <div className="signin_heading">Sign Up</div>
                                            <div className="signin_subheading mt-1">Please enter your details to register </div>
                                            <Form.Group controlId="businessName">
                                                <div
                                                    className={`form-floating mt-3 mb-3 ${touched.businessName && errors.businessName
                                                        ? 'has-validation-error'
                                                        : ''
                                                        }`}>
                                                    <Form.Control
                                                        type="text"
                                                        name="businessName"
                                                        placeholder="Business Name"
                                                        value={values.businessName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="w-100"
                                                        isInvalid={touched.businessName && errors.businessName}
                                                    />
                                                    {!values.businessName && (
                                                        <label htmlFor="businessName" className="greyLabel">
                                                            Business name<span className="required-icon">*</span>
                                                        </label>
                                                    )}
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.businessName}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>

                                            <Select
                                                options={orgTypeOptions}
                                                value={orgTypeVal}
                                                placeholder={
                                                    <div className="select-placeholder-text">Organization type<span className="required-icon">*</span></div>
                                                }
                                                onChange={handleOrgTypeDropdown}
                                                components={{
                                                    Option: (props) => (
                                                        <div
                                                            {...props.innerProps}
                                                            className={'org-type-item'}
                                                        >
                                                            {props.children}
                                                        </div>
                                                    ),
                                                }}
                                                className={orgTypeError === true ? 'has-validation-error w-100 org-type-dropdown mt-2' : 'w-100 org-type-dropdown mt-2'}
                                            />
                                            {
                                                orgTypeError === true &&
                                                <Form.Control.Feedback type="invalid">
                                                    Organization type is required
                                                </Form.Control.Feedback>

                                            }

                                            <Form.Group controlId="contactName">
                                                <div
                                                    className={`form-floating mt-3 mb-3 ${touched.contactName && errors.contactName
                                                        ? 'has-validation-error'
                                                        : ''
                                                        }`}>
                                                    <Form.Control
                                                        type="text"
                                                        name="contactName"
                                                        placeholder="Contact Person Name"
                                                        value={values.contactName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="w-100"
                                                        isInvalid={touched.contactName && errors.contactName}
                                                    />
                                                    {!values.contactName && (
                                                        <label htmlFor="contactName" className="greyLabel">
                                                            Contact person name<span className="required-icon">*</span>
                                                        </label>
                                                    )}
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contactName}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="email">
                                                <div
                                                    className={`form-floating mb-3 ${touched.email && errors.email
                                                        ? 'has-validation-error'
                                                        : ''
                                                        }`}>
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        placeholder="Business Email *"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="w-100"
                                                        isInvalid={touched.email && errors.email}
                                                    />
                                                    {!values.email && (
                                                        <label htmlFor="email" className="greyLabel">
                                                            Business Email <span className="required-icon">*</span>
                                                        </label>
                                                    )}
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="phoneNumber" className="w-100">
                                                <div
                                                    className={`form-floating mb-3 ${touched.phoneNumber && errors.phoneNumber
                                                        ? 'has-validation-error'
                                                        : ''
                                                        }`}>
                                                    <Controller
                                                        name="phoneNumber"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                            required: 'Phone number is required',
                                                            pattern: {
                                                                value: phoneRegExp,
                                                                message: 'Invalid phone number. Please use the format XXX-XXX-XXXX',
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <PhoneInput
                                                                country={'us'}
                                                                // value={values.phoneNumber}
                                                                value={phoneVal}
                                                                className="phone-input"
                                                                onChange={(e) => handlePhoneChange(e)}
                                                                onBlur={handleBlur}
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        showPhoneError === true &&
                                                        <Form.Control.Feedback type="" className="text-danger invalid-terms-feedback">
                                                            {phoneErrorMsg}
                                                        </Form.Control.Feedback>
                                                    }
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="Address">
                                                <div
                                                    className={`form-floating mt-3 mb-3 ${touched.Address && errors.Address
                                                        ? 'has-validation-error'
                                                        : ''
                                                        }`}>
                                                    <Form.Control
                                                        type="text"
                                                        name="Address"
                                                        placeholder="Contact Person Name"
                                                        value={values.Address}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="w-100"
                                                        isInvalid={touched.Address && errors.Address}
                                                    />
                                                    {!values.Address && (
                                                        <label htmlFor="Address" className="greyLabel">
                                                            Address
                                                        </label>
                                                    )}
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.Address}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>

                                            <div className="form-agree">
                                                <label className="form-check-label remember_me">
                                                    By signing up you agree to ChildUSA
                                                    <span className="terms_span cursor-pointer" onClick={() => navigate('/terms-conditions')}>&nbsp;terms & conditions</span>{" "}
                                                    and <span className="terms_span cursor-pointer" onClick={() => navigate('/privacy-policy')}>privacy policy</span>.
                                                </label>
                                            </div>

                                            <div className="d-grid gap-2 mt-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary signin_btn"
                                                    style={{ width: '100%' }}>
                                                    Create Account
                                                </button>
                                            </div>

                                            <div className="no-account-txt mt-2 cursor-pointer" onClick={() => navigate('/login')}>
                                                Already have an account <span className="login_here"> Sign in</span>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                    }
                </div>
            </div>
        </div>
    )
}

export default Signup;