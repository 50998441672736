import React, { useState, useEffect } from "react"
import svg from "../../assets/images/svg/index"
import { Button } from "react-bootstrap"
import { Menu, MenuItem } from "@mui/material"
import { useWindowSize } from "../../utils/useWindowSize.js"
import "react-toastify/dist/ReactToastify.css"
import "./styles.scss"
import { useNavigate, useLocation } from "react-router-dom"
import Logout from "../../Components/User/Logout"

let mobileBreakpoint = 820
const Navbar = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const ifLoggedIn =
    localStorage?.getItem("isLoggedin") === "true" ? true : false
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showLogout, setShowLogout] = useState(false)
  const [open, setOpen] = useState(false)
  const [activeNavItem, setActiveNavItem] = useState("Home")
  const [showHide, setHide] = useState(false)

  const scrollToSection = sectionId => {
    // setActiveNavItem(sectionName);
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  const handleNavigationClick = itemName => {
    setActiveNavItem(itemName)
    // Handle navigation to the clicked item if necessary
    if (itemName === "Dashboard") {
      window.location.href = "/dashboard"
    } else {
      // window.location.href = '/home';
      navigate("/home")
      scrollToSection(itemName.toLowerCase().replace(" ", "-"))
    }
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }
  const handleLogout = () => {
    setShowLogout(true)
    setOpen(false)
  }
  const handleDelClose = () => {
    setShowLogout(false)
  }

  useEffect(() => {
    if (location?.pathname === "/dashboard") {
      setHide(true)
      setActiveNavItem("Dashboard")
    } else {
      setHide(false)
    }
    // if (location?.pathname === '/home') {
    //     setActiveNavItem('Home');
    // }
  }, [])

  return (
    <>
      {getwidth > mobileBreakpoint ? (
        <div
          className={
            props?.hideHeaders === "true"
              ? "navbar-wrapper hideHeaders"
              : "navbar-wrapper"
          }
        >
          <div className="navbar-logo">
            <img
              src={svg?.Logo}
              className="navbar-logo-img cursor-pointer"
              alt="logo Np"
              onClick={() => navigate("/home")}
            />
          </div>

          {props?.hideHeaders !== "true" && (
            <div className="nav-items">
              <div
                className={`nav-item ${
                  activeNavItem === "Home" ? "active" : ""
                }`}
                onClick={() => handleNavigationClick("Home")}
              >
                Home
              </div>
              <div
                className={`nav-item ${
                  activeNavItem === "Dashboard" ? "active" : ""
                }`}
                onClick={() => (window.location.href = "/dashboard")}
              >
                Dashboard
              </div>
              {showHide === false && (
                <>
                  <div
                    className={`nav-item ${
                      activeNavItem === "about-us" ? "active" : ""
                    }`}
                    onClick={() => handleNavigationClick("about-us")}
                  >
                    About
                  </div>
                  <div
                    className={`nav-item ${
                      activeNavItem === "FAQ" ? "active" : ""
                    }`}
                    onClick={() => handleNavigationClick("FAQ")}
                  >
                    FAQ
                  </div>
                  <div
                    className={`nav-item ${
                      activeNavItem === "Contact us" ? "active" : ""
                    }`}
                    onClick={() => handleNavigationClick("Contact us")}
                  >
                    Contact us
                  </div>
                </>
              )}
            </div>
          )}
          <div className="login-btns d-flex align-items-center justify-content-center">
            <>
              {ifLoggedIn === true ? (
                <div className="user-info-wrapper d-flex align-items-center">
                  <div className="settings-img cursor-pointer">
                    <img
                      src={svg?.Settings}
                      className="wel-img"
                      alt="wel img"
                      onClick={handleMenuOpen}
                    />
                  </div>
                  <div className="welcome-txt d-flex flex-column">
                    <div className="wel-txt">Welcome</div>
                    <div className="user-name">
                      {localStorage?.getItem("businessName")}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Button
                    className="sign-up-btn"
                    onClick={() => (window.location.href = "/login")}
                  >
                    Sign In
                  </Button>
                  <div className="divider"></div>
                  <div
                    className="sign-in-btn"
                    onClick={() => (window.location.href = "/sign-up")}
                  >
                    Sign Up
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      ) : (
        <div
          className={
            props?.hideHeaders === "true"
              ? "navbar-wrapper hideHeaders"
              : "navbar-wrapper"
          }
        >
          <div
            className={
              getwidth >= mobileBreakpoint
                ? "logo-login-wrapper"
                : "logo-login-mob-wrapper"
            }
          >
            <div className="navbar-logo">
              <img
                src={svg?.Logo}
                className="navbar-logo-img cursor-pointer"
                alt="logo Np"
                onClick={() => navigate("/home")}
              />
            </div>
            <div className="login-btns d-flex align-items-center justify-content-center">
              <>
                {ifLoggedIn === true ? (
                  <div className="user-info-wrapper d-flex align-items-center">
                    <div className="settings-img cursor-pointer">
                      <img
                        src={svg?.Settings}
                        className="wel-img"
                        alt="wel img"
                        onClick={handleMenuOpen}
                      />
                    </div>
                    <div className="welcome-txt d-flex flex-column">
                      <div className="wel-txt">Welcome</div>
                      <div className="user-name">
                        {localStorage?.getItem("businessName")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <Button
                      className="sign-up-btn"
                      onClick={() => (window.location.href = "/login")}
                    >
                      Sign In
                    </Button>
                    <div className="divider"></div>
                    <div
                      className="sign-in-btn"
                      onClick={() => (window.location.href = "/sign-up")}
                    >
                      Sign Up
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
          {props?.hideHeaders !== "true" && (
            <div className="nav-items">
              <div
                className={`nav-item ${
                  activeNavItem === "Home" ? "active" : ""
                }`}
                onClick={() => handleNavigationClick("Home")}
              >
                Home
              </div>
              <div
                className={`nav-item ${
                  activeNavItem === "Dashboard" ? "active" : ""
                }`}
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </div>
              {showHide === false && (
                <>
                  <div
                    className={`nav-item ${
                      activeNavItem === "about-us" ? "active" : ""
                    }`}
                    onClick={() => handleNavigationClick("about-us")}
                  >
                    About
                  </div>
                  <div
                    className={`nav-item ${
                      activeNavItem === "FAQ" ? "active" : ""
                    }`}
                    onClick={() => handleNavigationClick("FAQ")}
                  >
                    FAQ
                  </div>
                  <div
                    className={`nav-item ${
                      activeNavItem === "Contact us" ? "active" : ""
                    }`}
                    onClick={() => handleNavigationClick("Contact us")}
                  >
                    Contact us
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        className="acc-settings-wrapper"
      >
        <MenuItem
          key={0}
          className="menu-item-noti"
          onClick={() => (window.location.href = "/settings")}
          // onClick={() => navigate('/settings')}
        >
          <img src={svg?.Account} className="acc-icon" alt="accicon" />
          <span className="settings-txt">Account Settings</span>
        </MenuItem>

        <MenuItem
          key={1}
          className="menu-item-noti"
          onClick={() => navigate("/change-password")}
        >
          <img src={svg?.PasswordSettings} className="acc-icon" alt="accicon" />
          <span className="settings-txt">Change Password</span>
        </MenuItem>
        <MenuItem
          key={2}
          className="menu-item-noti"
          onClick={() => navigate("/subscriptions")}
        >
          <img src={svg?.Subscription} className="acc-icon" alt="accicon" />
          <span className="settings-txt">My Plan</span>
        </MenuItem>
        <MenuItem
          key={3}
          className="menu-item-noti"
          onClick={() => navigate("/payments")}
        >
          <img src={svg?.Payment} className="acc-icon" alt="accicon" />
          <span className="settings-txt">Payments</span>
        </MenuItem>

        <MenuItem
          key={4}
          className="menu-item-noti logout-li"
          onClick={() => handleLogout()}
        >
          <img src={svg?.Logout} className="acc-icon" alt="accicon" />
          <span className="settings-txt">Log out</span>
        </MenuItem>
      </Menu>
      {showLogout === true && (
        <Logout handleDelClose={handleDelClose} show={showLogout} />
      )}
    </>
  )
}

export default Navbar
