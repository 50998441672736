import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import StepContent from "@mui/material/StepContent"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import TextField from "@mui/material/TextField"
import Loader from "../../../CustomComponents/Loader"
import Button from "@mui/material/Button"
import { getSurvey, saveSurvey } from "../../../api/request"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import FormControlLabel from "@mui/material/FormControlLabel"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./surveyForm.scss"
import { useNavigate } from "react-router-dom"

// const steps = [
//     {
//         label: 'Select campaign settings',
//         questions: [
//             {
//                 question: 'Question 1 for Step 1',
//                 id: '1',
//                 options: ['Yes', 'No'],
//             },
//             {
//                 question: 'Question 2 for Step 1',
//                 id: '2',
//                 options: ['Yes', 'No'],
//             },
//             {
//                 question: 'Question 1 for Step 1',
//                 id: '3',
//                 options: ['Yes', 'No'],
//             }
//         ],
//     },
//     {
//         label: 'Section 2',
//         questions: [
//             {
//                 question: 'Question 1 for Step 2',
//                 id: '11',
//                 options: ['Yes', 'No'],
//             },
//             {
//                 question: 'Question 2 for Step 2',
//                 id: '12',
//                 options: ['Yes', 'No'],
//             },
//         ],
//     },
//     {
//         label: 'Section 3',
//         questions: [
//             {
//                 question: 'Question 1 for Step 3',
//                 id: '31',
//                 options: ['Yes', 'No'],
//             },
//             {
//                 question: 'Question 2 for Step 3',
//                 id: '32',
//                 options: ['Yes', 'No'],
//             },
//             {
//                 question: 'Question 3 for Step 3',
//                 id: '33',
//                 options: ['Yes', 'No'],
//             }
//         ],
//     },
//     // Add similar structures for other steps
// ];

export default function SurveyForm(showForm = true) {
  const [userSelections, setUserSelections] = useState([])
  const [Loading, setLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [steps, setStepsData] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [surveyName, setSurveyname] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submittedAnswers, setSubmittedAnswers] = useState([])
  const [surveyId, setSurveyId] = useState(null)
  const [feedbackData, setFeedback] = useState("") // State for feedback
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      let resp = await getSurvey()
      setSurveyname(resp[0]?.name)
      setSurveyId(resp[0]?.id)
      let arr = []
      for (let i = 0; i < resp[0]?.sections?.length; i++) {
        arr?.push({
          label: resp[0]?.sections[i]?.name,
          questions: resp[0]?.sections[i]?.questions
        })
      }
      setStepsData(arr)
      setSelectedOptions(new Array(arr?.length).fill(null).map(() => ({})))
      setLoading(false)
    } catch (err) {
      toast.error(err?.response?.data?.message)
      setLoading(false)
    }
  }

  const handleOptionChange = (event, stepIndex, questionId) => {
    const newOptions = [...selectedOptions]
    newOptions[stepIndex][questionId] = { choice: event.target.value, text: newOptions?.[stepIndex]?.[questionId]?.text}
    setSelectedOptions(newOptions)
  }
  const handleInputChange = (event, stepIndex, questionId) => {
    const newOptions = [...selectedOptions]
    newOptions[stepIndex][questionId] = {choice: newOptions?.[stepIndex]?.[questionId]?.choice,text: event.target.value}
    setSelectedOptions(newOptions)
  }

  // const handleOptionChange = (event, stepIndex, questionIndex, choiceId) => {
  //     // 'stepIndex' is the index of the current section/step
  //     // 'questionIndex' is the index of the current question within the step
  //     // 'choiceId' is the ID of the selected choice for the question

  //     // Now, update the user's selections based on these parameters
  //     const updatedUserSelections = [...userSelections];
  //     updatedUserSelections[stepIndex] = { questionId: questionIndex, choiceId };
  //     setUserSelections(updatedUserSelections);
  //   };

  const handleNext = () => {
    console.log(steps[activeStep]);
    console.log(activeStep);
    const specificQuestionAnswers = selectedOptions[activeStep]
    const unanswered = steps[activeStep].questions?.filter(
      question => (!specificQuestionAnswers[question?.question?.id]?.choice || !specificQuestionAnswers[question?.question?.id]?.text)
    )
    let unansweredQuestions = []
    if (unanswered.length > 0) {
      unansweredQuestions = unansweredQuestions.concat(unanswered)
    }
    if (unansweredQuestions.length > 0) {
      // If there are unanswered questions, show an error message and don't submit
      // alert('Please answer all questions before submitting.');
      toast.error("Please answer all questions before submitting.")
      return
    }else{
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
    //
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
    setSelectedOptions(new Array(steps.length).fill(null).map(() => ({})))
  }

  const handleSubmit = async () => {
    let unansweredQuestions = []

    // Check if all questions are answered
    for (let i = 0; i < steps.length; i++) {
      const specificQuestionAnswers = selectedOptions[i]

      const unanswered = steps[i].questions?.filter(
        question => (!specificQuestionAnswers[question?.question?.id]?.choice || !specificQuestionAnswers[question?.question?.id]?.text)
      )

      if (unanswered.length > 0) {
        unansweredQuestions = unansweredQuestions.concat(unanswered)
      }
    }

    if (unansweredQuestions.length > 0) {
      // If there are unanswered questions, show an error message and don't submit
      // alert('Please answer all questions before submitting.');
      toast.error("Please answer all questions before submitting.")
      return
    }
    setIsSubmitting(true)

    const transformedArray = selectedOptions.reduce((result, obj) => {
      // Extract keys and values from the object
      const keys = Object.keys(obj)
      const values = Object.values(obj)

      // Iterate through the keys and values and push them into the result array
      keys.forEach((key, index) => {
        result.push({
          question: parseInt(key, 10), // Subtract 1 to make it zero-based
          choice: parseInt(values[index].choice),
          description: values[index].text
        })
      })

      return result
    }, [])

    const finalObject = {
      answer_data: transformedArray
    }

    let body = {
      answer_data: transformedArray,
      feedback: feedbackData
    }
    // console.log(answer_data, "answer_data");
    try {
      let resp = await saveSurvey(body, surveyId)
      setIsSubmitting(false)
      if (resp) {
        toast.success("Survey saved successfully")
        setTimeout(() => {
          navigate("/history")
        }, 800);
      }
    } catch (err) {
      setIsSubmitting(false)
      toast.error(
        err?.response?.data?.message ||
          " An error occurred while saving assessment"
      )
    }
  }

  const handleStepClick = index => {
    // You can add custom logic here if needed
    setActiveStep(index)
  }

  return (
    <div className="survey-form-wrapper">
      {Loading === true ? (
        <Loader loadingMsg="Please wait while data is fetching.." />
      ) : (
        <Box sx={{ maxWidth: 400 }}>
          <div className="survey-form-head">{surveyName}</div>
          <div className="survey-form-subhead">
            Please go through and answer all the questions listed below.{" "}
          </div>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step
                key={step.label}
                // onClick={() => handleStepClick(index)}
              >
                <StepLabel>
                  <div>
                    <Typography>Section {index + 1}</Typography>
                    <Typography variant="caption">{step.label}</Typography>
                  </div>
                </StepLabel>
                <StepContent>
                  <>
                    {step?.questions?.map((question, questionIndex) => (
                      <div key={questionIndex}>
                        <div className="ques-wrapper mb-2">
                          <Typography className="ques-num">
                            Q.{questionIndex + 1}
                          </Typography>
                          <Typography className="ques-name">
                            {question?.question?.title}
                          </Typography>
                        </div>

                        <RadioGroup
                          aria-label={`answer${questionIndex}`}
                          name={`answer${questionIndex}`}
                          value={
                            selectedOptions[index][question?.question?.id]?.choice || ""
                          } // Use '' as a default value
                          onChange={event =>
                            handleOptionChange(
                              event,
                              index,
                              question?.question?.id
                            )
                          }
                          className="option-radio"
                        >
                          {question?.question?.choice?.map(
                            (option, optionIndex) => (
                              <FormControlLabel
                                key={optionIndex}
                                value={option?.id}
                                control={<Radio />}
                                label={option?.choice_text}
                              />
                            )
                          )}
                        </RadioGroup>
                        <div className="input-box">
                          <TextField
                            variant="outlined"
                            label="Title of Policy"
                            placeholder="Title of Policy"
                            sx={{
                              "&.MuiFormControl-root": {
                                width: "400px"
                              }
                            }}
                            value={selectedOptions[index][question?.question?.id]?.text || ""}
                            onChange={event => handleInputChange(event, index, question?.question?.id)}
                          />
                        </div>
                      </div>
                    ))}
                    {index === steps.length - 1 && (
                      <>
                        <div className="feedback-txt">
                          Please mention if you have any additional feedback for
                          this assessment
                        </div>
                        <TextField
                          label="Feedback"
                          variant="outlined"
                          fullWidth
                          value={feedbackData}
                          onChange={event => setFeedback(event.target.value)}
                        />
                      </>
                    )}
                  </>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {index !== steps.length - 1 && (
                        <>
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                            className={
                              index === 0 ? "back-btn-disbaled" : "back-btn"
                            }
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Next
                          </Button>
                        </>
                      )}
                      {index === steps.length - 1 && (
                        <>
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                            className="back-btn"
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={isSubmitting}
                          >
                            Finish
                          </Button>
                        </>
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Box>
      )}
    </div>
  )
}
