import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
const mobileBreakpoint = 991;

export const useWindowSize = () => {
  const [size, setSize] = useState(getDefaultHeightAndWidth());
  useEffect(() => {
    const debouncedResize = debounce(() => {
      setSize([window?.innerHeight, window?.innerWidth, mobileBreakpoint]);
    }, 100);

    const onLoadWidth = () => {
      setSize([window?.innerHeight, window?.innerWidth, mobileBreakpoint]);
    };

    window?.addEventListener('load', onLoadWidth());

    window?.addEventListener('resize', debouncedResize);

    return () => {
      window?.removeEventListener('resize', debouncedResize);
      window?.removeEventListener('load', onLoadWidth);
    };
  }, []);

  return size;
};

const getDefaultHeightAndWidth = () => {
  return typeof window !== 'undefined'
    ? [window.innerHeight, window.innerWidth, mobileBreakpoint]
    : [undefined, undefined, mobileBreakpoint];
};