import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import svg from "../../../assets/images/svg/index"
import { Form } from "react-bootstrap"
import { useWindowSize } from "../../../utils/useWindowSize.js"
import { sendFeedback } from "../../../api/request"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./sendFeedback.scss"

let mobileBreakpoint = 821
const SendFeedback = () => {
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const [fileName, setFileName] = useState("")
  const [fileObj, setFileObj] = useState(null)
  const [showFileError, setFileError] = useState(false)
  const useremail = localStorage.getItem("userEmail")

  const schema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
    feedbackTitle: Yup.string()
      .required("Title is required")
      .max(250, "Title can have maximum of 250 chars."),
    feedbackEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .test("has-supported-tld", "Invalid email", value => {
        if (!value) {
          return false // Handle empty values as needed
        }

        const supportedTlds = [
          ".com",
          ".us",
          ".in",
          ".net",
          ".org",
          ".gov",
          ".edu",
          ".uk",
          ".au",
          ".co",
          ".io"
        ]
        const regex = new RegExp(`(${supportedTlds.join("|")})$`, "i")

        return regex.test(value)
      })
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  return (
    <div className="contact-us-wrapper">
      <div className="send-feed-head">Support/ Send Feedback</div>
      <div className="sub-head">
        Lets us know how we can make things better for you.
      </div>
      <Formik
        validationSchema={schema}
        initialValues={{
          feedbackTitle: "",
          message: "",
          feedbackEmail: useremail,
          file: ""
        }}
        onSubmit={async (values, { resetForm }) => {
          let body = {
            title: values.feedbackTitle,
            email: values.feedbackEmail,
            message: values.message,
            file: fileObj
          }
          let formData = new FormData()
          formData.append("title", values?.feedbackTitle)
          formData.append("message", values?.message)
          if (fileObj) {
            if (fileObj instanceof File) {
              formData.append("doc_file", fileObj)
            }
          }
          if (showFileError === true) {
            return
          }
          try {
            let resp = await sendFeedback(formData)
            if (resp) {
              toast.success(resp?.message)
              resetForm()
              setFileName("")
            }
          } catch (err) {
            toast.error(err?.response?.data?.message)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty
          /* and other goodies */
        }) => (
          <form className="login-form mt-3" onSubmit={handleSubmit} noValidate>
            <div className="Auth-form-content">
              <Form.Group controlId="feedbackEmail">
                <div
                  className={`form-floating mb-3 ${
                    touched.feedbackEmail && errors.feedbackEmail
                      ? "has-validation-error"
                      : ""
                  }`}
                >
                  <Form.Control
                    className={"w-100 mt-2 p-2 feedback-input"}
                    type="email"
                    name="feedbackEmail"
                    placeholder="Email"
                    value={values.feedbackEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={true}
                    isInvalid={touched.feedbackEmail && errors.feedbackEmail}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"}
                  >
                    {errors.feedbackEmail}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group controlId="feedbackTitle">
                <div
                  className={`form-floating mb-3 ${
                    touched.feedbackTitle && errors.feedbackTitle
                      ? "has-validation-error"
                      : ""
                  }`}
                >
                  <Form.Control
                    className={"w-100 mt-2 p-2 feedback-input"}
                    type="text"
                    name="feedbackTitle"
                    placeholder="Title"
                    value={values.feedbackTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.feedbackTitle && errors.feedbackTitle}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"}
                  >
                    {errors.feedbackTitle}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group controlId="message">
                <div
                  className={`form-floating mb-3 ${
                    touched.message && errors.message
                      ? "has-validation-error"
                      : ""
                  }`}
                >
                  <Form.Control
                    className={"w-100 mt-2 p-2 feedback-input"}
                    as="textarea"
                    rows={6}
                    style={{ height: "115px" }}
                    name="message"
                    placeholder="Your Message"
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.message && errors.message}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"}
                  >
                    {errors.message}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group controlId="fileUpload">
                <input
                  type="file"
                  id="file-input-rec"
                  className="file-input-rec"
                  name="file"
                  accept=".pdf, .doc, .docx"
                  onChange={e => {
                    const file = e.target.files[0]
                    if (file) {
                      const allowedExtensions = [".pdf", ".doc", ".docx"]
                      const isValidExtension = allowedExtensions.some(ext =>
                        file.name.toLowerCase().endsWith(ext)
                      )

                      if (!isValidExtension) {
                        // Show an error message or throw an error
                        toast.error("Please select a valid PDF or Word file.")
                        // You can also reset the file input if needed
                        e.target.value = null
                        setFileError(true)
                        return
                      } else {
                        setFileError(false)
                      }
                    }
                    handleChange(e)
                    setFileObj(file)
                    setFileName(file?.name)
                  }}
                />
                <label htmlFor="file-input-rec" className="file-upload-label">
                  {/* <span className="up-doc-txt">Upload Document</span> */}
                  <span className="up-doc-txt">
                    {fileName ? fileName : "Upload Document"}
                  </span>
                  <img
                    src={svg?.UploadFile}
                    className="file-img"
                    alt="file-img"
                  />
                </label>
                <Form.Control.Feedback
                  type="invalid"
                  className={getwidth >= mobileBreakpoint ? "w-50" : "w-100"}
                >
                  {errors.file}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="d-grid gap-2 mt-3">
                <button
                  type="submit"
                  className={
                    getwidth >= mobileBreakpoint
                      ? "btn btn-primary reset-btn"
                      : "btn btn-primary reset-btn w-100"
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}
export default SendFeedback
