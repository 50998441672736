import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    // Your Typeform API token
    const apiKey = 'LeQbNn9f';
    const formId = 'tfp_D9nyjVNNHpdLn3GtiekjCgcoeT3BJhRv5BS4yeveat2S_dLNz9NZoAqNh'; // Replace with your form ID

    axios.get(`https://api.typeform.com/forms/${formId}/responses`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      },
    })
    .then((response) => {
      setResponses(response.data.items);
    })
    .catch((error) => {
      console.error('Error fetching Typeform data', error);
    });
  }, []);

  return (
    <div>
      <h1>Typeform Responses</h1>
      <ul>
        {responses.map((response, index) => (
          <li key={index}>{response.answers[0].text}</li>
        ))}
      </ul>
    </div>
  );
}

export default App;
