import React, { useState } from "react"
import Navbar from "../../../CustomComponents/Navbar"
import BottomNavbar from "../../../CustomComponents/BottomNavbar"
import svg from "../../../assets/images/svg/index"
import { Button } from "react-bootstrap"
import ContactUsForm from "./ContactUsForm.jsx"
import { Accordion } from "react-bootstrap-accordion"
import "react-bootstrap-accordion/dist/index.css"
import "./styles.scss"

const Home = () => {
  const [faqData, setFaqData] = useState([
    {
      title:
        "How does this assessment help my organization prevent child sexual abuse (CSA)?",
      text: "The Gold Standard policy assessment is a tool for you and your organization to understand where there are gaps in your current written CSA prevention policies. Following the assessment, you can take the results and work with others in your organization to craft more comprehensive written policies and implement them with staff."
    },
    {
      title:
        "What if our organization wants a more detailed assessment of how to improve written CSA prevention policies?",
      text: "Reach out to CHILD USA at <a href='mailto:info@childusa.org'>info@childusa.org</a> to discuss an ongoing partnership to conduct in-depth policy analysis, resulting in a written report and consultation. Our Social Science Team will respond to start understanding your unique needs and to outline a partnership."
    },
    {
      title:
        "What about the implementation of these policies? How do I know whether our staff are putting these policies into practice? ",
      text: "CHILD USA has developed a separate survey instrument for leadership and staff in YSOs to understand the day-to-day implementation of CSA prevention policies. Reach out to <a href='mailto:info@childusa.org'>info@childusa.org</a> for more information about conducting this survey-based assessment in your organization."
    },
    {
      title:
        "How does payment work for organizations with various subdivisions? Can I purchase one assessment for the entire organization?",
      text: "Each purchased assessment may only be completed one time regarding one set of written CSA prevention policies. Therefore, if you have multiple subdivisions with different sets of written policies, you should purchase an assessment for each set of standards you wish to evaluate.<br/><br/>For example, if you are a national youth camp organization with regional chapters, you should consider purchasing an assessment for the national level and each regional chapter if there is an overarching child protection policy at the national level alongside policies developed by regional directors for each smaller organization. This will ensure that regional policies align with national organization standards and that the entire organization is assessing its liability and gaps in policy comprehensively."
    },
    {
      title: "How long will my assessment remain valid after payment?",
      text: "Access to the assessment tool will not expire until you complete and submit answers to all questions.  "
    },
    {
      title:
        "I don’t understand the results of the assessment. How do I interpret the results?",
      text: "The resulting data visualizations provide percentage scores for each policy domain, where the total possible score (100%) is based on answering “yes” to each question on the assessment tool."
    },
    {
      title: "How do I know the results of my assessment are accurate? ",
      text: "Our standards are based on the best available academic literature, expert opinion, and data regarding CSA prevention in YSOs. As the assessment is self-completed by YSO administrators, the accuracy of the results is dependent upon your answers faithfully reflecting the content of your written CSA prevention policies."
    },
    {
      title:
        "I don’t understand some of the questions in the assessment tool or how my policies match up. What should I do? ",
      text: "Use the feedback function in the assessment tool to directly communicate with CHILD USA’s research team regarding questions about the tool’s policy-related content. Alternatively, you may email <a href='mailto:info@childusa.org'>info@childusa.org</a> with any questions or concerns."
    }
  ])
  return (
    <>
      <Navbar />

      <div className="page-content">
        <div className="nav-page-body">
          <div id="home" className="content-section home-section">
            <div className="home-text-body">
              <div className="home-head">About the Gold Standard Program</div>
              <div className="home-body">
                CHILD USA's Gold Standard is a comprehensive scoring tool for
                child sexual abuse prevention policies in youth-serving
                organizations, including sports, religious organizations,
                scouting, schools, and many others. The Gold Standard assigns a
                score to each entity derived from multiple factors, including
                effective background screening, training, red-flag reporting,
                physical environment modifications, reporting requirements,
                victims' rights and assistance, and more. Our social scientists
                developed the standard by building on our groundbreaking
                research including our study of all Catholic archdioceses' CSA
                prevention policies, analysis of data about the Boy Scouts of
                America, spearheading the only comprehensive case study by
                national experts on the abuse of hundreds of victims by Larry
                Nassar, and high-level literature reviews on existing prevention
                policies. CHILD USA's goal is to bring prevention policies to a
                higher level nationwide and across all youth-serving
                organizations.
              </div>
              <Button
                className="take-survey-btn"
                onClick={() => (window.location.href = "/dashboard")}
              >
                Take an Assessment
              </Button>
            </div>

            <div className="home-img">
              <img src={svg?.Home} className="w-100 h-100" alt="home Np" />
            </div>
          </div>

          <div
            id="about-us"
            className="content-section about-us-section about-us-section"
          >
            <div className="home-img">
              <img src={svg?.HomeSec} className="w-100 h-100" alt="home Np" />
              {/* <video className="video" controls>
                                <source
                                //  src="https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_20mb.mp4"
                                 src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                                 type="video/mp4" />
                            </video> */}
            </div>
            <div className="home-text-body">
              <div className="home-head">About Us</div>
              <div className="home-body">
                CHILD USA is the leading national think tank fighting for the
                civil rights of children. Our mission is to employ in-depth
                legal analysis and cutting-edge social science research to
                protect children, prevent future abuse and neglect, and bring
                justice to survivors.
                <div className="about-listing-wrapper">
                  We achieve this mission through:
                  <ul className="">
                    <li>
                      Fighting for the rights of children to be free from child
                      sex abuse through legal reform of the statutes of
                      limitation and federal bankruptcy law, ending child
                      marriage, and holding institutions accountable.
                    </li>
                    <li>
                      Fighting for the rights of children to medical care.
                    </li>
                    <li>
                      Fighting to ensure every child's right to a quality
                      education.
                    </li>
                  </ul>
                </div>
              </div>
              {/* <Button className='take-survey-btn'>Take a Assessment</Button> */}
            </div>
          </div>

          <div id="faq" className="content-section faq-section">
            <div className="faq-head">FAQ</div>
            <div className="faq-sub-title">CHILD USA’s Gold Standard</div>
            <div className="faq-body-wrapper">
              {faqData?.map((data, index) => (
                <Accordion title={data?.title} className="faq-accordian-title">
                  <div
                    className="faq-items-body"
                    dangerouslySetInnerHTML={{ __html: data?.text }}
                  ></div>
                </Accordion>
              ))}
            </div>
          </div>
          <div id="contact-us" className="content-section contact-us-section">
            <div className="contact-us-head">Contact us</div>
            <div className="contact-us-sub-title">
              Feel free to reach out to us with any inquiries you may have.
              Kindly provide your email address, and we will respond promptly to
              address your point.
            </div>
            <div className="contact-us-form">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
      <BottomNavbar />
    </>
  )
}

export default Home
